import { Button, Modal } from 'antd'
import React from 'react'
import Webcam from 'react-webcam'
import './index.css'


interface ICapturePhotoModalProps {
    isModalOpen: boolean,
    toggleModal: () => void
    setImageUrl: (src: string) => void

}

const CapturePhotoModal = (props: ICapturePhotoModalProps) => {

    const { isModalOpen, toggleModal, setImageUrl } = props

    const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: "user"
    };

    const webcamRef = React.useRef<Webcam>(null);

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef?.current?.getScreenshot()
        if (imageSrc) setImageUrl(imageSrc)

    },
        [webcamRef]
    );

    return (
        <Modal title="Capturar Foto"
            bodyStyle={{ 'display': 'flex', justifyContent: 'center' }}
            open={isModalOpen} onOk={toggleModal}
            cancelText="Cerrar"
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={toggleModal}
            className='capture-photo-modal'
        >
            <div style={
                { display: 'flex', flexDirection: 'column' }
            }>
                <Webcam
                    audio={false}
                    height={450}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={620}
                    videoConstraints={videoConstraints}
                />
                <Button className='capture-button' onClick={capture}>Capturar Foto</Button>
            </div>
        </Modal>
    )
}

export default CapturePhotoModal
