import React, { useEffect, useState } from 'react'
import './EditClient.css'
import { Alert, Button, Form, Layout, message, notification, theme } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { IClientModel } from '../../../types/models/client';
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload/interface';
import {
    ArrowLeftOutlined,
    LoadingOutlined,
    PlusOutlined
} from '@ant-design/icons';
import ClientForm, { IClientInitialValues } from '../../Forms/ClientForm';
import { getClient, updateClient } from '../../../server/clients/clients';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/stores/store';
import { setToast } from '../../../redux/slices/uiSlice';
import ConfirmPasswordModal from '../../Common/Modals/ConfirmPassword';
import { confirmPassword } from '../../../server/users/users';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

const EditClient = () => {

    const { Content } = Layout;

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const navigate = useNavigate()


    const user = useSelector((state: RootState) => state.user)
    const userId = user._id;
    const token = user.token;
    const centerId = user.centers?.length ? user.centers[0] : ''
    const [loading, setLoading] = useState(false);
    const [invalidConfirmation, setInvalidConfirmation] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();
    const dispatch = useDispatch()
    const [fileList, setFileList] = useState<UploadFile<any>[]>([])
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [errorOnSave, setErrorOnSave] = useState<string>('');
    const [newValues, setNewValues] = useState<IClientModel>({} as IClientModel)
    const [modalProps, setModalProps] = useState<{
        waitingForConfirmation: boolean,
        isModalOpen: boolean
        input: string

    }>({
        waitingForConfirmation: false,
        isModalOpen: false,
        input: '',

    })
    const [clientModel, setClientModel] = useState<IClientInitialValues>(
        {
            _id: '',
            enterDate: dayjs(new Date()),
            paymentDate: dayjs(new Date()),
            lastName: '',
            name: '',
            userId: '',
            centerId: '',
            email: '',
            phoneNumber: '',
            profilePicture: '',
            phonePrefix: '506',
            photoUrl: ''
        }
    )

    const params = useParams()

    useEffect(() => {
        const clientId = params?.clientId

        if (clientId && token) {
            (async () => {
                const clientData = await getClient(clientId, token)

                const client: IClientModel | null = clientData?.data || null

                const photoUrl = client?.photoUrl || ''

                if (photoUrl) {
                    const storage = getStorage();
                    getDownloadURL(ref(storage, photoUrl))
                        .then((url) => {
                            setImageUrl(url)
                        })
                        .catch((error) => {
                            console.log(error);
                            // Handle any errors
                        });
                }

                if (client) {
                    setClientModel({
                        _id: client._id,
                        name: client.name,
                        lastName: client.lastName,
                        userId: client.userId,
                        centerId: client.centerId,
                        email: client.email,
                        phoneNumber: client.phoneNumber,
                        enterDate: dayjs(new Date(client.enterDate)),
                        paymentDate: dayjs(new Date(client.paymentDate)),
                        phonePrefix: client.phonePrefix || "506",
                        photoUrl: client.photoUrl,
                    })
                }
            })()
        }
    }, [token])

    const handleChange: UploadProps['onChange'] = ({ fileList }) => {
        setFileList(fileList)
    };

    const onSubmit = async (values: IClientModel) => {
        setIsSubmitting(true)
        setNewValues({ ...values })
        if (dayjs(values.paymentDate).diff(dayjs(clientModel.paymentDate)) !== 0) setModalProps({ ...modalProps, isModalOpen: true })
        else await saveData(values)
    }

    const saveData = async (values: IClientModel) => {
        const clientId = params?.clientId

        let photoFile: File | null = null;

        if (imageUrl && !imageUrl.includes('firebase')) {
            await fetch(imageUrl)
                .then(res => res.blob())
                .then(blob => {
                    photoFile = new File([blob], 'profile-pic', blob)
                })
        }

        const file = fileList.length ? fileList[0].originFileObj : photoFile ?? null

        const data = {
            _id: clientId,
            name: values.name,
            lastName: values.lastName,
            userId: values.userId,
            centerId,
            email: values.email,
            phoneNumber: values.phoneNumber,
            enterDate: new Date(values.enterDate),
            paymentDate: new Date(values.paymentDate),
            active: true,
            phonePrefix: values.phonePrefix,
            canAccess: true,
            photoFile: file,
            photoUrl: clientModel.photoUrl
        }

        const response = await updateClient(data, token);

        const docId = response?._id;
        if (docId) {
            dispatch(setToast({ message: "Cliente Actualizado Correctamente", kind: 'success' }))
            navigate('/clients')
        }
        else {
            dispatch(setToast({ message: "No posible actualizar los datos del cliente", kind: 'error' }))
            const errorType: 'id' | 'email' | 'phoneNumber' = response?.duplicate
            if (errorType === 'id') setErrorOnSave("Cédula de cliente ya ha sido utilizada")
            else if (errorType === 'email') setErrorOnSave("Correo electrónico de cliente ya ha sido utilizado")
            else if (errorType === 'phoneNumber') setErrorOnSave("Número de telefono de cliente ya ha sido utilizado")
        }
        setIsSubmitting(false)
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Subir Foto</div>
        </div>
    );

    const onPasswordInput = (input: string) => {
        setModalProps({ ...modalProps, input })
    }

    const handleConfirmPassword = async () => {
        setModalProps({ ...modalProps, waitingForConfirmation: true })
        const password = modalProps.input


        if (userId) {
            const isValidPassword = await confirmPassword(userId, password, token)
            console.log(isValidPassword);

            if (isValidPassword) await saveData(newValues)
            else setInvalidConfirmation(true)
        }
        setModalProps({ ...modalProps, waitingForConfirmation: false })
    }

    const changeImageUrl = (src: string) => setImageUrl(src)

    return (
        <>
            <ConfirmPasswordModal
                {...modalProps}
                onConfirm={handleConfirmPassword}
                onInput={onPasswordInput}
                error={invalidConfirmation}
                toggleModal={() => {
                    setModalProps({ ...modalProps, isModalOpen: !modalProps.isModalOpen })
                    setIsSubmitting(false)
                }}
            />
            <Layout>
                <Content className='main-content-container'>
                    <div className='main-content' style={{ background: colorBgContainer }}>
                        <div className='content-header'>
                            <h3>Editar Cliente</h3>
                            <Button onClick={() => navigate('/clients')} icon={<ArrowLeftOutlined />}>Atrás</Button>
                        </div>
                        {errorOnSave && (<Alert style={{ margin: '1rem 0' }} message={errorOnSave} type="error" />)}
                        <div className='form'>
                            <ClientForm
                                data={clientModel}
                                setImageUrl={changeImageUrl}
                                onSubmit={onSubmit}
                                isSubmitting={isSubmitting}
                                imageUrl={imageUrl}
                                beforeUpload={() => false}
                                handleUploadChange={handleChange}
                                uploadButton={uploadButton}
                                setData={setClientModel}
                                fileList={fileList}
                            />
                        </div>
                    </div>
                </Content>
            </Layout >
        </>
    )
}

export default EditClient