import { Alert, Button, Input, Modal } from 'antd'
import QRCode from 'qrcode';
import { ExclamationCircleFilled } from '@ant-design/icons';
import React, { useEffect } from 'react'
import { IClientModel } from '../../../../types/models/client';

interface IModalProps {
    isModalOpen: boolean;
    waitingForConfirmation: boolean
    onConfirm: () => Promise<void>
    toggleModal: () => void
    onInput: (input: string) => void
    error: boolean
}

const ConfirmPasswordModal = (props: IModalProps) => {

    const { isModalOpen, toggleModal, waitingForConfirmation, onConfirm, onInput, error } = props


    return (
        <Modal title="Ingresar Contraseña"
            bodyStyle={{ 'display': 'flex', justifyContent: 'center' }}
            open={isModalOpen}
            onOk={onConfirm}
            confirmLoading={waitingForConfirmation}
            onCancel={toggleModal}>
            <div style={
                { display: 'flex', flexDirection: 'column' }
            }>
                <p>Por razones de seguridad es necesario que ingrese su contraseña para poder continuar con la acción.</p>
                {error && <Alert message="Contraseña incorrecta, verifique e intente de nuevo." type="error" style={{ marginBottom: '1rem' }} />}
                <Input.Password name="password" type='password' placeholder='Ingrese su contraseña...' onInput={(e) => {
                    const target = e.target as HTMLInputElement
                    const value = target.value
                    onInput(value)
                }} />
            </div>
        </Modal>
    )
}

export default ConfirmPasswordModal