// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY || '',
  authDomain: 'procentersoft-34ba0.firebaseapp.com',
  projectId: 'procentersoft-34ba0',
  storageBucket: 'procentersoft-34ba0.appspot.com',
  messagingSenderId: '251664962798',
  appId: '1:251664962798:web:aed9be1a7c408007244fff',
  measurementId: 'G-DBJ9QL4TJ3',
};
