import AES from 'crypto-js/aes';

const base = process.env.REACT_APP_API_ORIGIN;

export const getUser = async (token: string) => {
  try {
    const response = await fetch(`${base}/api/validate-session`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = response.json();
    return data;
  } catch (error) {
    console.log('Error on GetUser', error);
    return {};
  }
};

export const login = async (username: string, pass: string) => {
  try {
    const payload = {
      username,
      pass,
    };
    const response = await fetch(`${base}/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const data = response.json();
    return data;
  } catch (error) {
    console.log('Error on GetUser', error);
    return {};
  }
};

export const confirmPassword = async (
  userId: string,
  password: string,
  token: string
) => {
  try {
    const passPhrase = process.env.REACT_APP_PASS_PHRASE;

    if (passPhrase) {
      const encryptedPassword = AES.encrypt(password, passPhrase).toString();
      const encodedPassword = encodeURIComponent(encryptedPassword);
      const response = await fetch(
        `${base}/api/validate-password?id=${userId}&password=${encodedPassword}`,
        {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const data = await response.json();
      const isValidPassword = data?.success;
      return isValidPassword;
    }

    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};
