import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type TToast = { message: string; kind: 'success' | 'error' };

interface IUiState {
  currentTab: string;
  toast: TToast;
}

const initialState: IUiState = {
  currentTab: '',
  toast: { message: '', kind: 'success' },
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setUiState: (state, action: PayloadAction<IUiState>) => {
      state.currentTab = action.payload.currentTab;
    },
    setToast: (state, action: PayloadAction<TToast>) => {
      state.toast.kind = action.payload.kind;
      state.toast.message = action.payload.message;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUiState, setToast } = uiSlice.actions;

export default uiSlice.reducer;
