import React, { useState } from 'react'
import { Alert, Layout, Tag, theme } from 'antd';
import { Content } from 'antd/es/layout/layout';
import './Scanner.css'
import { IClientModel } from '../../types/models/client';
import { createClientEntryLog, getClient } from '../../server/clients/clients';
import QrReader from '../Common/QrReader';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/stores/store';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

const Scanner = () => {

    const [client, setClient] = useState<IClientModel | null>(null)
    const [imageUrl, setImageUrl] = useState<string>('')
    const [warning, setWarning] = useState<{ display: boolean, message: string }>({
        display: false,
        message: ''
    })
    const user = useSelector((state: RootState) => state.user)
    const token = user.token

    const scanClient = async (clientId: string, centerId: string) => {
        const response = await getClient(clientId, token)
        const client = response?.data as IClientModel;

        if (client) {
            if (client.active) {
                setClient(client)
                setWarning(
                    { ...warning, display: false }
                )
                if (client._id) await createClientEntryLog({ clientId: client._id, centerId, date: new Date() }, token)
            }
            else {
                setClient(null)
                setImageUrl('')
                setWarning({
                    display: true,
                    message: 'Hemos encontrado un problema... Este cliente se encuentra inactivo. Verifique los datos.'
                })
            }

            const photoUrl = client?.photoUrl || ''

            if (photoUrl) {
                const storage = getStorage();
                getDownloadURL(ref(storage, photoUrl))
                    .then((url) => {
                        setImageUrl(url)
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    }

    const clearData = () => {
        setClient(null)
        setImageUrl('')
        setWarning({ ...warning, display: false })
    }

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (

        <Layout>
            <Content className='main-content-container'>
                <div className='main-content' style={{ background: colorBgContainer }} >
                    <h3>Scanner Códigos QR</h3>
                    <div className='scanner-content'>
                        <QrReader callback={scanClient} clearData={clearData} />
                        {(client?._id &&
                            <>
                                <h3>Información del Cliente</h3>
                                <div className='profile-text-info'>
                                    {imageUrl && <img src={imageUrl} alt="client" height={160} />}
                                    <section className='client-details-section'>
                                        <p>Cliente: <span>{client.name} {client.lastName}</span></p>
                                        <p>Estado: <span>{client.active ? 'Activo' : 'Inactivo'}</span></p>
                                        <p>Pago Pendiente: <span>{client.canAccess ? <Tag className='custom-tag' color="green" bordered={false}>NO</Tag> : <Tag color="red" bordered={false}>Sí</Tag>}</span></p>
                                        <p>Próximo Pago: <span>{new Date(client.paymentDate).toLocaleDateString()}</span></p>
                                    </section>
                                </div>
                            </>
                        )}
                        {warning.display && (
                            <Alert message={warning.message} type="error" showIcon style={{ width: '100%' }} />
                        )}
                    </div>
                </div>

            </Content>
        </Layout>

    );
}

export default Scanner