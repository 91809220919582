import { Layout, theme } from 'antd'
import { QRCodeCanvas } from 'qrcode.react';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { IClientModel } from '../../../types/models/client';
import { getClient } from '../../../server/clients/clients';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/stores/store';

const { Content } = Layout

const ClientOverview = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [url, setUrl] = useState("");
    const [client, setClient] = useState<IClientModel>({} as IClientModel);
    const token = useSelector((state: RootState) => state.user.token)
    const params = useParams()

    useEffect(() => {
        const clientId = params.clientId;
        const base = process.env.REACT_APP_CLIENT_ORIGIN || '';
        if (clientId) {
            const getData = async () => {
                const response = await getClient(clientId, token)
                const client = response?.data as IClientModel
                if (client) {
                    setUrl(`${base}/clients/scan/check?client=${clientId}&center=${client.centerId}`)
                    if (client) setClient(client)
                }
            }

            getData()
        }

    }, [])

    const qrcode = (
        <QRCodeCanvas
            id="qrCode"
            value={url}
            size={250}
            level={"H"}
        />
    );

    return (
        <Layout>
            <Content className='main-content-container'>
                <div className='main-content' style={{ background: colorBgContainer }}>
                    {client?._id && (
                        <>
                            <div>{qrcode}</div>
                            <div className='info'>
                                <p>Nombre: {`${client.name} ${client.lastName}`}</p>
                                <p>Estado: {client.active ? 'Activo' : 'Inactivo'}</p>
                                <p>Pagos Pendientes: {client.canAccess ? 'No' : 'Si'}</p>
                            </div>
                        </>
                    )}
                </div>

            </Content>
        </Layout>
    )
}

export default ClientOverview