import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Login from './components/Login/Login';
import Home from './components/Home/Home';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import { Provider } from 'react-redux'
import { store } from './redux/stores/store';
import './index.css'
import ClientsList from './components/Clients/List/ClientsList';
import NewClient from './components/Clients/New/NewClient';
import EditClient from './components/Clients/Edit/EditClient';
import CenterOverview from './components/Centers/Overview/CenterOverview';
import NewCenter from './components/Centers/New/NewCenter';
import EditCenter from './components/Centers/Edit/EditCenter';
import ClientOverview from './components/Clients/Overview/ClientOverview';
import Scanner from './components/Scanner/Scanner';
import { ConfigProvider } from 'antd';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />

  },
  {
    path: "/home",
    element:
      <ProtectedRoute>
        <Home />
      </ProtectedRoute >,
  },
  {
    path: "/clients",
    element:
      <ProtectedRoute>
        <ClientsList />
      </ProtectedRoute >,
  },
  {
    path: 'clients/new',
    element:
      <ProtectedRoute>
        <NewClient />
      </ProtectedRoute >,
  },
  {
    path: 'clients/:clientId/edit',
    element:
      <ProtectedRoute>
        <EditClient />
      </ProtectedRoute >,
  },
  {
    path: 'clients/:clientId/overview',
    element:
      <ProtectedRoute>
        <ClientOverview />
      </ProtectedRoute >,
  },
  {
    path: 'centers/overview',
    element:
      <ProtectedRoute>
        <CenterOverview />
      </ProtectedRoute >,
  },
  {
    path: 'centers/new',
    element:
      <ProtectedRoute>
        <NewCenter />
      </ProtectedRoute >,
  },
  {
    path: 'centers/:centerId/edit',
    element:
      <ProtectedRoute>
        <EditCenter />
      </ProtectedRoute >,
  },
  {
    path: '/utility/scanner',
    element:
      <ProtectedRoute>
        <Scanner />
      </ProtectedRoute >,
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <React.StrictMode>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "'Lora', Arial, sans-serif",
          },
        }}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
    </React.StrictMode>,
  </Provider>
)