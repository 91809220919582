import React, { useEffect, useState } from 'react'
import { Button, Input, Layout, Space, theme } from 'antd'
import Table, { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { useMediaQuery } from 'react-responsive';
import { getCentersList, searchCenter } from '../../../server/centers';
import { ICenterModel } from '../../../types/models/center';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/stores/store';

interface DataType {
    key: string;
    name: string;
    address: string;
    phoneNumber: string;
    email: string;
}

const CenterOverview = () => {

    const { Content } = Layout;
    const { Search } = Input;


    const token = useSelector((state: RootState) => state.user.token)

    const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
    const navigate = useNavigate()
    const [searchInput, setSearchInput] = useState<string>('')
    const [value] = useDebounce(searchInput, 500);
    const [dataSource, setDataSource] = useState<DataType[]>([])

    const handleEdit = (row: DataType) => navigate(`/centers/${row.key}/edit`)

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const columns: ColumnsType<DataType> = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            render: (text: string) => <a>{text}</a>,
        },
        {
            title: 'Dirección',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Teléfono',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: 'Correo Electrónico',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Acciones',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type='link' onClick={() => handleEdit(record)}>Editar</Button>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        if (value?.length > 2 && token) (async () => { await search(value) })()
        else if (!value && token) (async () => { await getData() })()
    }, [value, token])

    const search = async (value: string) => {
        const res = await searchCenter(value, token)
        const centers = res?.data || []
        buildData(centers)
    }

    const getData = async () => {
        const centers = await getCentersList('test-tenant', token)
        const data = centers?.data || []
        if (data?.length) buildData(data)
    }

    const buildData = (data: ICenterModel[]) => {
        const sourceData: DataType[] = []

        if (data.length) {
            for (const center of data) {
                const data: DataType = {
                    key: center._id || '',
                    name: center.name,
                    phoneNumber: center.phoneNumber || '-',
                    email: center.email || '-',
                    address: center.address
                }

                sourceData.push(data)
            }
        }
        setDataSource(sourceData)
    }


    return (
        <Layout>
            <Content className='main-content-container'>
                <div className='main-content' style={{ background: colorBgContainer }}>
                    <div className='clients-table-header'>
                        <h3>Centros</h3>
                        <Button disabled={dataSource?.length > 0} onClick={() => navigate('/centers/new')}>Nuevo</Button>
                    </div>
                    <Search placeholder="Buscar un centro..." onChange={e => setSearchInput(e.target.value)} />
                    <div>
                        <Table columns={columns} dataSource={dataSource} scroll={{ x: isMobile ? 1000 : 0 }} pagination={{ pageSize: 15 }} />
                    </div>
                </div>
            </Content>
        </Layout>
    )
}

export default CenterOverview