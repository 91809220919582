import { Modal } from 'antd'
import React from 'react'

import { IClientTableDataType } from '../../../../types/common/Components';

interface IModalProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    handleChange: () => void
    client: IClientTableDataType,
    isSubmittingData: boolean
}

const ChangeClientStatusModal = (props: IModalProps) => {
    const { isModalOpen, toggleModal, client, handleChange, isSubmittingData } = props

    const clientName = client.name;
    const currentStatus = client.active

    return (
        <Modal title={`${currentStatus ? 'Inactivar' : 'Activar'} cliente`}
            bodyStyle={{ 'display': 'flex', justifyContent: 'center' }}
            open={isModalOpen}
            onOk={handleChange}
            confirmLoading={isSubmittingData}
            onCancel={toggleModal}
            closable={false}
        >
            <p>¿Está seguro que desea {`${currentStatus ? 'inactivar' : 'activar'}`} el cliente <span style={{ fontWeight: 'bold' }}>{clientName}</span>?</p>
        </Modal>
    )
}

export default ChangeClientStatusModal