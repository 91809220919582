import React, { useEffect, useState } from 'react'
import { Alert, Button, Layout, message, notification, theme } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload/interface';
import {
    ArrowLeftOutlined,
    LoadingOutlined,
    PlusOutlined
} from '@ant-design/icons';
import CenterForm, { ICenterInitialValues } from '../../Forms/CenterForm';
import { getCenter, updateCenter } from '../../../server/centers';
import { ICenterModel } from '../../../types/models/center';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/stores/store';
import { setToast } from '../../../redux/slices/uiSlice';

const EditCenter = () => {

    const { Content } = Layout;
    const token = useSelector((state: RootState) => state.user.token)

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [imageUrl, setImageUrl] = useState<string>();
    const [errorOnSave, setErrorOnSave] = useState<string>('');
    const dispatch = useDispatch()
    const [centerModel, setCenterModel] = useState<ICenterInitialValues>(
        {
            _id: '',
            name: '',
            email: '',
            phoneNumber: '',
            tenantId: '',
            address: '',
            picture: ''
        }
    )

    const params = useParams()

    useEffect(() => {
        const centerId = params?.centerId;
        if (centerId && token) {
            (async () => {
                const centerData = await getCenter(centerId, token)

                const center: ICenterModel | null = centerData?.data || null

                if (center) {
                    setCenterModel({
                        _id: center._id || '',
                        name: center.name,
                        tenantId: center.tenantId,
                        email: center.email,
                        phoneNumber: center.phoneNumber,
                        picture: center.picture,
                        address: center.address,
                    })
                }
            })()
        }
    }, [token])

    const onSubmit = async (values: ICenterModel) => {
        setIsSubmitting(true)
        const centerId = params?.centerId
        const data = {
            _id: centerId,
            name: values.name,
            email: values.email,
            phoneNumber: values.phoneNumber,
            address: values.address,
            picture: '',
            tenantId: centerModel.tenantId
        }
        const response = await updateCenter(data, token);


        const docId = response?._id;
        if (docId) {
            dispatch(setToast({ message: "Datos del centro han sido actualizados", kind: 'success' }))
            navigate('/centers/overview')
        }
        else {
            dispatch(setToast({ message: "No es posible actualizar los datos del centro", kind: 'error' }))
        }
        setIsSubmitting(false)
    }
    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj as RcFile, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Subir Foto</div>
        </div>
    );

    return (
        <>
            <Layout>
                <Content className='main-content-container'>
                    <div className='main-content' style={{ background: colorBgContainer }}>
                        <div className='content-header'>
                            <h3>Editar Cliente</h3>
                            <Button onClick={() => navigate('/centers/overview')} icon={<ArrowLeftOutlined />}>Atrás</Button>
                        </div>
                        {errorOnSave && (<Alert style={{ margin: '1rem 0' }} message={errorOnSave} type="error" />)}
                        <div className='form'>
                            <CenterForm
                                data={centerModel}
                                onSubmit={onSubmit}
                                imageUrl={imageUrl}
                                isSubmitting={isSubmitting}
                                beforeUpload={beforeUpload}
                                handleUploadChange={handleChange}
                                uploadButton={uploadButton} />
                        </div>
                    </div>
                </Content>
            </Layout >
        </>
    )
}

export default EditCenter