import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { getUser } from '../../server/users/users'
import { setUser } from '../../redux/slices/userSlice'
import { IUserModel } from '../../types/models/users'
import './index.css'
import LeftNav from '../LeftNav/LeftNav'
import { useIdleTimer } from 'react-idle-timer';
import { RootState } from '../../redux/stores/store'
import useNotification from 'antd/es/notification/useNotification'
import { setToast } from '../../redux/slices/uiSlice'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { firebaseConfig } from '../../server/firebase/firebase'

interface IProtectedProps { children: React.ReactElement }

const ProtectedRoute = (props: IProtectedProps) => {

    const { children } = props
    const [state, setState] = useState<string>('Active')
    const [count, setCount] = useState<number>(0)
    const [remaining, setRemaining] = useState<number>(0)
    const [notificationApi, contextHolder] = useNotification();
    const toast = useSelector((state: RootState) => state.ui.toast)
    const navigate = useNavigate()
    const [invalidToken, setInvalidToken] = useState<boolean>(false)
    const dispatch = useDispatch()

    const onIdle = () => {
        setState('Idle')
        dispatch(setToast({ kind: 'success', message: '' }))
        const session_token = document.cookie.split('session_token=')[1] || ''
        document.cookie = `session_token=${session_token}; path=/; expires=Thu, 30 Jun 2013 00:00:01 GMT;`
        navigate('/')
    }

    const onActive = () => {
        setState('Active')
    }

    const onAction = () => {
        setCount(count + 1)
    }

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        onActive,
        onAction,
        timeout: 7200000,
        throttle: 500
    })

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    })

    useEffect(() => {

        const value = `; ${document.cookie}`;
        const parts = value.split(`; session_token=`);
        const session_token = parts?.pop()?.split(';').shift() || '';

        const verifyToken = async () => {
            const response: IUserModel = await getUser(session_token)

            if (response?._id) dispatch(setUser({
                _id: response._id,
                username: '',
                name: '',
                middleName: '',
                token: session_token,
                lastName: '',
                passwordHash: '',
                email: '',
                centers: response.centers
            }))
            else setInvalidToken(true)
        }

        if (session_token) verifyToken()
        else setInvalidToken(true)

        const firebaseApp = initializeApp(firebaseConfig);
        getAnalytics(firebaseApp);
    }, [])

    useEffect(() => {

        if (toast.message) {
            notificationApi.info({
                message: toast.kind === 'success' ? 'Operación Exitosa' : 'Algo Salió Mal',
                description: toast.message,
                placement: "topRight",
                type: toast.kind,
                duration: 5
            });
        }

    }, [toast])

    if (invalidToken) return <Navigate to="/" replace />

    return (
        <>
            {contextHolder}
            <LeftNav>
                {children}
            </LeftNav>
        </>
    )
}

export default ProtectedRoute