import React from 'react'
import { Alert, Button, Form, Input } from 'antd';
import './Login.css'
import { useState } from 'react';
import { login } from '../../server/users/users';
import { useNavigate } from 'react-router-dom';
import { IUserModel } from '../../types/models/users';


const Login = () => {
    const [isLoginIn, setIsLoginIn] = useState<boolean>(false)
    const [noUserFound, setUserNotFound] = useState<boolean>(false)
    const navigate = useNavigate();

    const onFinish = async (values: any) => {
        setIsLoginIn(true)
        const data: IUserModel = await login(values.username, values.password)
        if (data) {
            if (data.token) {
                document.cookie = `session_token=${data.token}; path=/`
            }
            return navigate("/home");
        }
        else setUserNotFound(true)
        setIsLoginIn(false)
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='login-container'>
            {noUserFound && (<Alert className='error-alert' message="Contraseña y/o usuario son incorrectos" type="error" />)}
            <Form
                name="basic"
                layout='vertical'
                style={{ width: 400 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Usuario"
                    name="username"
                    rules={[{ required: true, message: 'Este campo es requerido' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Contraseña"
                    name="password"
                    rules={[{ required: true, message: 'Este campo es requerido' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={isLoginIn}>
                        Ingresar
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default Login