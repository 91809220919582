import React, { useEffect, useState } from 'react'
import {
    HomeOutlined,
    PieChartOutlined,
    TeamOutlined,
    MessageOutlined,
    PicCenterOutlined,
    ScanOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import { Button, MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import './LeftNav.css'
import { setToast } from '../../redux/slices/uiSlice';
import { useDispatch } from 'react-redux';

interface ILeftNavProps {
    children: React.ReactElement
}

const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number] & {
    route: string,
    onClick: () => void,
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
};

function getItem(
    route: string,
    onClick: () => void,
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        onClick,
        route
    } as MenuItem;
}

const LeftNav = (props: ILeftNavProps) => {

    const { children } = props

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [currentTabSelection, setCurrentTabSelection] = useState<string[]>([])

    const items: MenuItem[] = [
        getItem('home/', () => { navigate('/home') }, 'Inicio', '1', <HomeOutlined />),
        getItem('/centers/overview', () => { navigate('/centers/overview') }, 'Centros', '2', <PicCenterOutlined />),
        getItem('', () => ({}), 'Avisos', '3', <MessageOutlined />),
        getItem('', () => ({}), 'Usuarios', 'sub1', <TeamOutlined />,
            [getItem('/clients', () => { navigate('/clients') }, 'Clientes', '4'), getItem('', () => ({}), 'Personal', '5')]),
        getItem('', () => ({}), 'Reportes', 'sub2', <PieChartOutlined />, [
            getItem('', () => ({}), 'Reporte #1', '6'),
            getItem('', () => ({}), 'Reporte #2', '7'),
        ]),
        getItem('', () => { navigate('/utility/scanner') }, 'Escáner', '8', <ScanOutlined />),
    ];


    const closeSession = () => {
        dispatch(setToast({ kind: 'success', message: '' }))
        const session_token = document.cookie.split('session_token=')[1] || ''
        document.cookie = `session_token=${session_token}; path=/; expires=Thu, 30 Jun 2013 00:00:01 GMT;`
        navigate('/')
    }

    useEffect(() => {
        const url = window.location.href
        const currentTabUrl = url.split('/')[3]
        if (currentTabUrl) {
            const item = items.find(item => item?.route.includes(currentTabUrl))

            if (!item) {
                items.filter(item => item?.children)
                    .filter(child => child.children?.forEach(child => {
                        if (child.route.includes(currentTabUrl)) setCurrentTabSelection([String(child?.key)])

                    }))
            } else {
                if (item?.key) setCurrentTabSelection([String(item.key)])
            }
        }
    }, [])

    return (
        <Layout style={{ height: '100vh' }}>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
            >
                <div>
                    <div style={{ height: 32, margin: 16, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'rgba(255, 255, 255, 0.2)' }} >
                        <h4 style={{ color: 'white' }}>Pro Center</h4>
                    </div>
                    {currentTabSelection.length && (
                        <Menu
                            theme="dark"
                            mode="inline"
                            defaultSelectedKeys={currentTabSelection}
                            items={items}
                        />
                    )}
                </div>
                <div className='logout-section'>
                    <Button onClick={closeSession} type='link' icon={<LogoutOutlined />}>Cerrar Sesión</Button>
                </div>
            </Sider>
            {children}
        </Layout>
    )
}


export default LeftNav