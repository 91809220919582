import { Spin } from 'antd';
import jsQR from 'jsqr';
import React, { useEffect, useState } from 'react'


const QrReader = (props: { callback: (clientId: string, tenantId: string) => Promise<void>, clearData: () => void }) => {

    const { callback, clearData } = props

    const [lastCodeRead, setLastCodeRead] = useState<string>('')
    const [videoElement, setVideoElement] = useState<HTMLVideoElement>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const MINUTE_MS = 30000;


    const scanQRCode = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const videoWidth = 300;
        const videoHeight = 300;
        canvas.width = videoWidth;
        canvas.height = videoHeight;
        if (context && videoElement) {
            context.drawImage(videoElement, 0, 0, videoWidth, videoHeight);

            const imageData = context.getImageData(0, 0, videoWidth, videoHeight);
            const code = jsQR(imageData.data, imageData.width, imageData.height);

            if (code && lastCodeRead !== code.data) {
                setLastCodeRead(code.data)
            }

            requestAnimationFrame(scanQRCode);
        }
    }

    useEffect(() => {
        const element = document.getElementById('cam-scan') as HTMLVideoElement;
        setVideoElement(element)
        if (videoElement) {
            setIsLoading(false)
            scanQRCode()
            navigator.mediaDevices.getUserMedia({ video: true, audio: false })
                .then(function (stream) {
                    videoElement.srcObject = stream;
                    videoElement.play()
                    requestAnimationFrame(scanQRCode);
                })
                .catch(function (error) {
                    console.error('Error accessing webcam:', error);
                });
        }

        return (() => {
            const videoStream = element.srcObject as MediaStream
            if (videoStream) {
                const tracks = videoStream.getVideoTracks();
                tracks.forEach(function (track) {
                    track.enabled = false
                    track.stop();
                    element.src = ''
                });
            }

        })

    }, [videoElement])


    useEffect(() => {
        if (lastCodeRead) {
            const url = new URL(lastCodeRead)
            const params = new URLSearchParams(url.search);
            const clientId = params.get('client')
            const centerId = params.get('center')

            if (clientId && centerId) callback(clientId, centerId)
        }

        const interval = setInterval(() => {


            if (lastCodeRead !== '') {
                setLastCodeRead('')
                clearData()
            }
        }, MINUTE_MS);

        return () => clearInterval(interval);
    }, [lastCodeRead])

    return (
        <>
            <Spin spinning={isLoading}></Spin>
            <video id="cam-scan" style={{ position: 'relative', top: '-50px', visibility: `${isLoading ? 'hidden' : 'visible'}` }} width={300} height={300}></video>
        </>
    )
}

export default QrReader