import React, { useState } from 'react'
import './NewCenter.css'
import { Alert, Button, Form, Layout, message, notification, theme } from 'antd'
import { useNavigate } from 'react-router-dom';
import {
    ArrowLeftOutlined,
    LoadingOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload/interface';
import CenterForm, { ICenterInitialValues } from '../../Forms/CenterForm';
import { ICenterModel } from '../../../types/models/center';
import { saveNewCenter } from '../../../server/centers';
import { RootState } from '../../../redux/stores/store';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from '../../../redux/slices/uiSlice';

const NewCenter = () => {

    const { Content } = Layout;

    const token = useSelector((state: RootState) => state.user.token)

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const dispatch = useDispatch()
    const [imageUrl, setImageUrl] = useState<string>();
    const [errorOnSave, setErrorOnSave] = useState<string>('');
    const [centerModel, setCenterModel] = useState<ICenterInitialValues>(
        {
            _id: '',
            name: '',
            email: '',
            phoneNumber: '',
            tenantId: '',
            address: '',
            picture: ''
        }
    )

    const onSubmit = async (values: ICenterModel) => {
        setIsSubmitting(true)
        const data = {
            name: values.name,
            email: values.email,
            phoneNumber: values.phoneNumber,
            tenantId: 'test-tenant',
            picture: '',
            address: values.address
        }
        const response = await saveNewCenter(data, token)

        const docId = response?._id;
        if (docId) {
            dispatch(setToast({ kind: 'success', message: 'Centro creado correctamente' }))
            navigate('/centers/overview')
        }
        else {
            dispatch(setToast({ kind: 'error', message: 'No es posible crear el centro' }))
        }
        setIsSubmitting(false)
    }

    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj as RcFile, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Subir Foto</div>
        </div>
    );

    return (
        <>
            <Layout>
                <Content className='main-content-container'>
                    <div className='main-content' style={{ background: colorBgContainer }}>
                        <div className='content-header'>
                            <h3>Nuevo Centro</h3>
                            <Button onClick={() => navigate('/centers/overview')} icon={<ArrowLeftOutlined />} >Atrás</Button>
                        </div>
                        {errorOnSave && (<Alert style={{ margin: '1rem 0' }} message={errorOnSave} type="error" />)}
                        <div className='form'>
                            <CenterForm
                                data={centerModel}
                                onSubmit={onSubmit}
                                isSubmitting={isSubmitting}
                                imageUrl={imageUrl}
                                beforeUpload={beforeUpload}
                                handleUploadChange={handleChange}
                                uploadButton={uploadButton} />
                        </div>
                    </div>
                </Content>
            </Layout >
        </>
    )
}

export default NewCenter