import { ICenterModel } from '../../types/models/center';

const base = process.env.REACT_APP_API_ORIGIN;

export const saveNewCenter = async (payload: ICenterModel, token: string) => {
  try {
    const response = await fetch(`${base}/api/save-center`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    const data = response.json();
    return data;
  } catch (error) {
    console.log('Error on POST - saveNewClient', error);
    return;
  }
};

export const getCentersList = async (tenantId: string, token: string) => {
  const response = await fetch(`${base}/api/center-list?tenantId=${tenantId}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = response.json();
  return data;
};

export const getCenter = async (id: string, token: string) => {
  const response = await fetch(`${base}/api/center?centerId=${id}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = response.json();
  return data;
};

export const updateCenter = async (payload: ICenterModel, token: string) => {
  try {
    const response = await fetch(`${base}/api/update-center`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    const data = response.json();
    return data;
  } catch (error) {
    console.log('Error on POST - updateClient', error);
    return;
  }
};

export const searchCenter = async (str: string, token: string) => {
  const response = await fetch(`${base}/api/search-centers`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ str }),
  });
  const data = response.json();
  return data;
};
