import { Button, DatePicker, Form, FormInstance, Input, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { IClientModel } from '../../types/models/client'
import { RcFile, UploadChangeParam, UploadFile } from 'antd/es/upload'
import { Dayjs } from 'dayjs'
import { ICenterModel } from '../../types/models/center'

export interface ICenterInitialValues {
    _id: string,
    tenantId: string,
    name: string,
    picture: string,
    address: string,
    phoneNumber: string,
    email: string
}

interface ICenterFormProps {
    onSubmit: (values: ICenterModel) => Promise<void>,
    beforeUpload: (file: RcFile) => boolean,
    handleUploadChange: (info: UploadChangeParam<UploadFile<any>>) => void | undefined,
    imageUrl: string | undefined,
    uploadButton: React.ReactNode,
    data: ICenterInitialValues
    isSubmitting: boolean
}

const CenterForm = (props: ICenterFormProps) => {

    const { onSubmit, beforeUpload, handleUploadChange, imageUrl, uploadButton, data, isSubmitting } = props
    const [form] = Form.useForm()


    useEffect(() => {
        if (form && data) form.setFieldsValue({ ...data })
    }, [data])

    return (
        <Form
            name="basic"
            layout='vertical'
            form={form}
            style={{ width: '100%' }}
            initialValues={{ ...data }}
            onFinish={onSubmit}
            autoComplete="off"
        >
            <Form.Item>
                <Upload
                    name="avatar"
                    listType="picture-circle"
                    className="avatar-uploader"
                    showUploadList={false}
                    action=""
                    beforeUpload={beforeUpload}
                    onChange={handleUploadChange}
                >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
                <Form.Item
                    label="Nombre"
                    name="name"
                    rules={[{ required: true, message: 'Este campo es requerido' }]}
                    style={{ display: 'inline-block', width: '50%' }}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Dirección"
                    name="address"
                    rules={[{ required: true, message: 'Este campo es requerido' }]}
                    style={{ display: 'inline-block', width: '50%' }}
                >
                    <Input style={{ marginLeft: 10 }} />
                </Form.Item>

                <Form.Item
                    label="Teléfono"
                    name="phoneNumber"
                    rules={[{ required: true, message: 'Este campo es requerido' }]}
                    style={{ display: 'inline-block', width: '50%' }}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Correo Electrónico"
                    name="email"
                    rules={[
                        { required: true, message: 'Este campo es requerido' },
                        { pattern: /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm, message: "Formato de correo no válido" }]}
                    style={{ display: 'inline-block', width: '50%' }}
                >
                    <Input style={{ marginLeft: 10 }} />
                </Form.Item>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" disabled={isSubmitting}>
                    Guardar
                </Button>
            </Form.Item>
        </Form>
    )
}

export default CenterForm