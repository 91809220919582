import React, { useEffect, useState } from 'react'
import './ClientList.css'
import { Layout, Space, theme, Input, Button, Select, Avatar } from 'antd'
import Table, { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { filterClients, updateClientStatus } from '../../../server/clients/clients';
import { IClientModel } from '../../../types/models/client';
import { useMediaQuery } from 'react-responsive';
import { useDebounce } from 'use-debounce';
import ClientQrModal from '../../Common/Modals/ClientQrModal';
import ChangeClientStatusModal from '../../Common/Modals/ChangeClientStatusModal';
import { IClientTableDataType } from '../../../types/common/Components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/stores/store';
import moment from 'moment';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { UserOutlined } from '@ant-design/icons';


const { Content } = Layout
const { Search } = Input;


const ClientsList: React.FC = () => {

    const navigate = useNavigate()
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
    const user = useSelector((state: RootState) => state.user)
    const token = user.token;
    const centers = user.centers
    const [dataSource, setDataSource] = useState<IClientTableDataType[]>([])
    const [searchInput, setSearchInput] = useState<string>('')
    const [isSubmittingData, setIsSubmittingData] = useState<boolean>(false)
    const [modals, setModals] = useState<{ isQrModalOpen: boolean, isChangeStatusModalOpen: boolean }>({ isChangeStatusModalOpen: false, isQrModalOpen: false })
    const [selectedClient, setSelectedClient] = useState<IClientTableDataType | null>(null)
    const [url, setUrl] = useState("");
    const [value] = useDebounce(searchInput, 500);
    const [selectedStatus, setSelectedStatus] = useState<'all' | 'active' | 'inactive' | 'defaulter' | 'nonDefaulter'>('active')
    const [clientName, setClientName] = useState('')

    const gotoEdit = (row: IClientTableDataType) => navigate(`${row.key}/edit`)

    const handleQRAction = async (clientId: string, centerId: string) => {

        const clientName = dataSource.find(data => data.key === clientId)?.name || '';

        setClientName(clientName)

        const baseUrl = process.env.REACT_APP_CLIENT_ORIGIN || '';
        setUrl(`${baseUrl}/clients/scan/check?client=${clientId}&center=${centerId}`)
        toggleQrModal()
    }

    const toggleQrModal = () => setModals({ ...modals, isQrModalOpen: !modals.isQrModalOpen })
    const toggleChangeStatusModal = () => setModals({ ...modals, isChangeStatusModalOpen: !modals.isChangeStatusModalOpen })

    const handleStatusChange = async () => {
        if (selectedClient?.key) {
            setIsSubmittingData(true)
            const { key, active } = selectedClient
            await updateClientStatus({ _id: key, nextState: !active }, token)
            await getData()
            setIsSubmittingData(false)
            setSelectedClient(null)
            toggleChangeStatusModal()
        }
    }

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const columns: ColumnsType<IClientTableDataType> = [
        {
            title: 'Foto',
            key: 'photo',
            dataIndex: 'photo',
            render: (_, record) => (
                record.photoUrl ?
                    <img height={60} width={60} src={record.photoUrl} alt="profile-pic" />
                    : <Avatar style={{ backgroundColor: '#DE6B48' }} shape="square" size={60} icon={<UserOutlined />} />
            ),

        },
        {
            title: 'Cliente',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Cédula',
            dataIndex: 'userId',
            key: 'userId',
        },
        {
            title: 'Teléfono',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: 'Correo Electrónico',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Ingreso',
            dataIndex: 'enterDate',
            key: 'enterDate',
        },
        {
            title: 'Próximo Pago',
            key: 'paymentDate',
            dataIndex: 'paymentDate',
            render: (_, record) => (
                <span className={`${record.canAccess ? '' : 'error-span'}`} >{record.paymentDate}</span>
            ),
            sorter: (a, b) => moment(a.paymentDate, 'DD-MM-YYYY').diff(moment(b.paymentDate, 'DD-MM-YYYY')),

        },
        {
            title: 'Estado',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Acciones',
            key: 'action',
            render: (_, record) => (
                <Space size="small">
                    <Button type="link" block onClick={() => handleQRAction(record.key, record.centerId)}>
                        QR
                    </Button>
                    <Button type="link" block onClick={() => gotoEdit(record)}>Editar</Button>
                    <Button type="link" block onClick={() => {
                        setSelectedClient(record)
                        toggleChangeStatusModal()
                    }}>
                        {record.active ? 'Desactivar' : 'Activar'}
                    </Button>
                </Space>
            ),
        },
    ];

    const buildData = async (clients: IClientModel[]) => {
        const sourceData: IClientTableDataType[] = []

        if (clients.length) {
            for (const client of clients) {
                const photoStorage = client?.photoUrl || '';

                let photoUrl = ''

                if (photoStorage) {
                    const storage = getStorage();
                    await getDownloadURL(ref(storage, photoStorage))
                        .then((url) => {
                            photoUrl = url
                        })
                        .catch((error) => {
                            console.log(error);
                            // Handle any errors
                        });
                }

                const data: IClientTableDataType = {
                    key: client._id || '',
                    name: `${client.name} ${client.lastName}`,
                    status: client.active ? 'Activo' : 'Inactivo',
                    userId: client.userId,
                    enterDate: new Date(client.enterDate).toLocaleDateString(),
                    phoneNumber: client.phoneNumber || '-',
                    email: client.email || '-',
                    centerId: client.centerId || '',
                    active: client.active,
                    canAccess: client.canAccess,
                    photoUrl,
                    paymentDate: new Date(client.paymentDate).toLocaleDateString(),
                }

                sourceData.push(data)
            }
        }
        setDataSource(sourceData)
    }

    useEffect(() => {
        if (value?.length > 2 && token) (async () => { await search(value) })()
        else if (!value && token) (async () => { await getData() })()
    }, [value, token])

    const search = async (value: string) => {
        const centerId = centers?.length ? centers[0] : '';
        if (centerId) {
            const response = await filterClients(selectedStatus, centerId, token, value)
            const data = response?.data || []
            buildData(data)
        }
    }

    const getData = async () => {
        const centerId = centers?.length ? centers[0] : '';
        if (centerId) {
            const response = await filterClients(selectedStatus, centerId, token)
            const data = response?.data || []
            buildData(data)
        }
    }

    const handleChange = async (value: 'active' | 'defaulter' | 'all' | 'inactive' | 'nonDefaulter') => {
        const centerId = centers?.length ? centers[0] : '';
        setSelectedStatus(value)
        if (centerId) {
            const response = await filterClients(value, centerId, token)
            const data = response?.data || []
            buildData(data)
        }
    };

    // const test = async () => {
    //     const data = await updateAccessList()
    // }

    return (
        <>
            <Layout style={{ overflow: 'auto' }}>
                <Content className='main-content-container'>
                    <div className='main-content' style={{ background: colorBgContainer }}>
                        <div>
                            <div className='clients-table-header'>
                                <h3>Clientes</h3>
                                <Button onClick={() => navigate('/clients/new')}>Nuevo</Button>
                                {/* <Button onClick={test}>TEST ME</Button> */}
                            </div>
                            <Space className='clients-action-buttons'>
                                <div className='select-filter'>
                                    <span>Filtrar por:</span>
                                    <Select
                                        defaultValue="active"
                                        style={{ width: 200 }}
                                        onChange={handleChange}
                                        options={[
                                            { value: 'active', label: 'Activos' },
                                            { value: 'all', label: 'Todos' },
                                            { value: 'inactive', label: 'Inactivos' },
                                            { value: 'nonDefaulter', label: 'Pago al Día', },
                                            { value: 'defaulter', label: 'Pago Pendiente', },
                                        ]}
                                    />
                                </div>
                            </Space>
                            <Search
                                placeholder="Buscar un cliente..."
                                style={{ marginBottom: '1rem' }}
                                onChange={e => setSearchInput(e.target.value)} />
                        </div>
                        <div>
                            <Table columns={columns}
                                dataSource={dataSource}
                                scroll={{ x: isMobile ? 1000 : 0 }}
                                pagination={{ pageSize: 15 }} />
                        </div>
                    </div>
                </Content>
            </Layout>
            <ClientQrModal isModalOpen={modals.isQrModalOpen}
                toggleModal={toggleQrModal}
                qrURL={url}
                clientName={clientName} />
            {selectedClient && (
                <ChangeClientStatusModal
                    isModalOpen={modals.isChangeStatusModalOpen}
                    toggleModal={toggleChangeStatusModal}
                    handleChange={handleStatusChange}
                    client={selectedClient}
                    isSubmittingData={isSubmittingData}
                />
            )}
        </>
    )
}


export default ClientsList