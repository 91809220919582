import { Button, Modal } from 'antd'
import QRCode from 'qrcode';
import React, { useEffect } from 'react'

interface IModalProps {
    isModalOpen: boolean;
    qrURL: string;
    clientName: string
    toggleModal: () => void
}

const downloadQR = (name: string) => {
    const canvas = document.getElementById("qr-code-canvas") as HTMLCanvasElement;
    const pngUrl = canvas?.toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${name}-qr-code.png` || "qr-code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};



const ClientQrModal = (props: IModalProps) => {
    const { isModalOpen, toggleModal, qrURL, clientName } = props

    useEffect(() => {
        const canvas = document.getElementById('qr-code-canvas')
        if (canvas) {
            QRCode.toCanvas(canvas, qrURL, { width: 300 })
        }
    })


    return (
        <Modal title="QR del Cliente"
            bodyStyle={{ 'display': 'flex', justifyContent: 'center' }}
            open={isModalOpen} onOk={toggleModal}
            onCancel={toggleModal}>
            <div style={
                { display: 'flex', flexDirection: 'column' }
            }>
                <canvas id="qr-code-canvas" />
                <Button type='link' onClick={() => downloadQR(clientName?.toLocaleLowerCase().replaceAll(' ', '-'))}>Descargar</Button>
            </div>
        </Modal>
    )
}

export default ClientQrModal