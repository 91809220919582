import React, { useState } from 'react'
import './NewClient.css'
import { Alert, Button, Layout, theme } from 'antd'
import { useNavigate } from 'react-router-dom';
import {
    ArrowLeftOutlined,
    LoadingOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { IClientModel } from '../../../types/models/client';
import { saveNewClient } from '../../../server/clients/clients';
import ClientForm, { IClientInitialValues } from '../../Forms/ClientForm';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/stores/store';
import { setToast } from '../../../redux/slices/uiSlice';

const NewClient = () => {

    const { Content } = Layout;

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const navigate = useNavigate()
    const user = useSelector((state: RootState) => state.user)
    const token = user.token;
    const centerId = user.centers?.length ? user.centers[0] : ''
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();
    const [errorOnSave, setErrorOnSave] = useState<string>('');
    const dispatch = useDispatch()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [fileList, setFileList] = useState<UploadFile<any>[]>([])
    const [clientModel, setClientModel] = useState<IClientInitialValues>(
        {
            _id: '',
            enterDate: dayjs(new Date()),
            paymentDate: dayjs(new Date()),
            lastName: '',
            name: '',
            userId: '',
            centerId: '',
            email: '',
            phoneNumber: '',
            profilePicture: '',
            phonePrefix: "506",
        }
    )

    const onSubmit = async (values: IClientModel) => {


        let photoFile: File | null = null;

        if (imageUrl && !imageUrl.includes('firebase')) {
            console.log(imageUrl);

            await fetch(imageUrl)
                .then(res => res.blob())
                .then(blob => {
                    photoFile = new File([blob], 'profile-pic', blob)
                })
        }

        const file = fileList.length ? fileList[0].originFileObj : photoFile ?? null

        setIsSubmitting(true)
        const data = {
            name: values.name,
            lastName: values.lastName,
            userId: values.userId,
            centerId,
            email: values.email,
            phoneNumber: values.phoneNumber,
            enterDate: new Date(values.enterDate),
            paymentDate: new Date(values.paymentDate),
            active: true,
            phonePrefix: values.phonePrefix,
            canAccess: true,
            photoFile: file,
            photoUrl: ''
        }

        const response = await saveNewClient(data, token)

        const docId = response?._id;
        if (docId) {
            dispatch(setToast({ kind: 'success', message: 'Cliente Actualizado Correctamente' }))
            navigate('/clients')
        }
        else {
            dispatch(setToast({ kind: 'error', message: 'No es posible crear el cliente' }))
            const errorType: 'id' | 'email' | 'phoneNumber' = response?.duplicate
            if (errorType === 'id') setErrorOnSave("Cédula de cliente ya ha sido utilizada")
            else if (errorType === 'email') setErrorOnSave("Correo electrónico de cliente ya ha sido utilizado")
            else if (errorType === 'phoneNumber') setErrorOnSave("Número de telefono de cliente ya ha sido utilizado")
        }
        setIsSubmitting(false)
    }

    const handleChange: UploadProps['onChange'] = ({ fileList }) => {
        setFileList(fileList)
    };

    const changeImageUrl = (src: string) => setImageUrl(src)

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Subir Foto</div>
        </div>
    );

    return (
        <>
            <Layout>
                <Content className='main-content-container'>
                    <div className='main-content' style={{ background: colorBgContainer }}>
                        <div className='content-header'>
                            <h3>Nuevo Cliente</h3>
                            <Button onClick={() => navigate('/clients')} icon={<ArrowLeftOutlined />} >Atrás</Button>
                        </div>
                        {errorOnSave && (<Alert style={{ margin: '1rem 0' }} message={errorOnSave} type="error" />)}
                        <div className='form'>
                            <ClientForm
                                data={clientModel}
                                setImageUrl={changeImageUrl}
                                onSubmit={onSubmit}
                                isSubmitting={isSubmitting}
                                imageUrl={imageUrl}
                                beforeUpload={() => false}
                                handleUploadChange={handleChange}
                                uploadButton={uploadButton}
                                setData={setClientModel}
                                fileList={fileList}
                            />
                        </div>
                    </div>
                </Content>
            </Layout >
        </>
    )
}

export default NewClient