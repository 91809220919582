import { IClientModel } from '../../types/models/client';
import QRCode from 'qrcode';
import { deleteObject, getStorage, ref, uploadBytes } from 'firebase/storage';

const base = process.env.REACT_APP_API_ORIGIN;

export const saveNewClient = async (payload: IClientModel, token: string) => {
  try {
    if (payload.photoFile) {
      const storage = getStorage();
      const storageRef = ref(
        storage,
        `/clients-pictures/${crypto.randomUUID()}`
      );

      await uploadBytes(storageRef, payload.photoFile).then((snapshot) => {
        payload = { ...payload, photoUrl: snapshot.metadata.fullPath };
      });
    }

    console.log(payload.photoUrl);

    delete payload.photoFile;

    const response = await fetch(`${base}/api/save-client`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();

    const clientId = data?._id;

    if (clientId) {
      const url = `${base}/clients/scan/check?client=${clientId}&center=${payload.centerId}`;

      QRCode.toDataURL(url, { type: 'image/png', width: 600 }).then(
        async (img) => {
          const res = await sendQrCodeEmail(
            {
              name: `${payload.name} ${payload.lastName}`,
              email: payload.email,
              centerName: 'Test Center',
              file: img.split(',')[1],
            },
            token
          );
          console.log('YES', res);
        }
      );
    }
    return data;
  } catch (error) {
    console.log('Error on POST - saveNewClient', error);
    return;
  }
};

export const getClientsList = async (centerId: string, token: string) => {
  const response = await fetch(
    `${base}/api/clients-list?centerId=${centerId}`,
    {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const data = response.json();
  return data;
};

export const getClient = async (id: string, token: string) => {
  const response = await fetch(`${base}/api/client?clientId=${id}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = response.json();
  return data;
};

export const updateClient = async (payload: IClientModel, token: string) => {
  try {
    if (payload.photoFile) {
      const storage = getStorage();
      const storageRef = ref(
        storage,
        `/clients-pictures/${crypto.randomUUID()}`
      );

      const previousPhotoUrl = payload.photoUrl;

      await uploadBytes(storageRef, payload.photoFile).then((snapshot) => {
        payload = { ...payload, photoUrl: snapshot.metadata.fullPath };
      });

      if (previousPhotoUrl) {
        const desertRef = ref(storage, previousPhotoUrl);

        // Delete the file
        await deleteObject(desertRef);
      }
    }

    delete payload.photoFile;
    const response = await fetch(`${base}/api/update-client`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    const data = response.json();
    return data;
  } catch (error) {
    console.log('Error on POST - updateClient', error);
    return;
  }
};

export const updateClientStatus = async (
  payload: { _id: string; nextState: boolean },
  token: string
) => {
  try {
    const response = await fetch(`${base}/api/update-client-status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    const data = response.json();
    return data;
  } catch (error) {
    console.log('Error on POST - updateClient', error);
    return;
  }
};

export const searchClients = async (str: string, token: string) => {
  const response = await fetch(`${base}/api/search-clients`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ str }),
  });
  const data = response.json();
  return data;
};

//EMAILS

const sendQrCodeEmail = async (
  payload: {
    name: string;
    email: string;
    centerName: string;
    file: string;
  },
  token: string
) => {
  try {
    const response = await fetch(`${base}/api/send-qr-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...payload }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.log('Error on POST - sendQrCodeEmail', error);
    return;
  }
};

export const filterClients = async (
  status: 'active' | 'defaulter' | 'all' | 'inactive' | 'nonDefaulter',
  centerId: string,
  token: string,
  str = ''
) => {
  const response = await fetch(
    `${base}/api/find-clients-by-status?centerId=${centerId}&status=${status}&value=${str}`,
    {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const data = response.json();
  return data;
};

export const createClientEntryLog = async (
  payload: { centerId: string; clientId: string; date: Date },
  token: string
) => {
  try {
    const response = await fetch(`${base}/api/create-client-entry-log`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...payload }),
    });

    const data = await response.json();

    if (data) return { success: true };
    else return { error: true };
  } catch (error) {
    console.log(error);
    return;
  }
};

export const updateAccessList = async () => {
  const response = await fetch(`${base}/api/cron-payment-verification`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = response.json();
  return data;
};
