import './Home.css'
import React from 'react';
import { Layout, theme } from 'antd';


const { Content } = Layout;

const Home: React.FC = () => {

    const {
        token: { colorBgContainer },
    } = theme.useToken()

    return (
        <Layout>
            <Content className='main-content-container'>
                <div className='main-content' style={{ background: colorBgContainer }}>
                    Inicio
                </div>
            </Content>
        </Layout>
    );
};

export default Home;