import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IUserModel } from '../../types/models/users';

const initialState: IUserModel = {
  _id: '',
  username: '',
  name: '',
  middleName: '',
  lastName: '',
  passwordHash: '',
  token: '',
  email: '',
  centers: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUserModel>) => {
      state.username = action.payload.username;
      state._id = action.payload._id;
      state.token = action.payload.token;
      state.centers = action.payload.centers || [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser } = userSlice.actions;

export default userSlice.reducer;
