import { Avatar, Button, DatePicker, Form, Input, Select, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { IClientModel } from '../../types/models/client'
import { RcFile, UploadChangeParam, UploadFile } from 'antd/es/upload'
import { Dayjs } from 'dayjs'
import CapturePhotoModal from '../Common/Modals/CapturePhotoModal/CapturePhotoModal'

export interface IClientInitialValues extends Pick<IClientModel, '_id' |
    'profilePicture' |
    'name' |
    'lastName' |
    'userId' |
    'email' |
    'phoneNumber' |
    'centerId' |
    'phonePrefix'> {
    enterDate: Dayjs,
    paymentDate: Dayjs,
    photoUrl?: string
}

interface IClientFormProps {
    onSubmit: (values: IClientModel) => Promise<void>,
    beforeUpload: (file: RcFile) => boolean,
    handleUploadChange: (info: UploadChangeParam<UploadFile<any>>) => void | undefined,
    imageUrl: string | undefined,
    uploadButton: React.ReactNode,
    data: IClientInitialValues,
    isSubmitting: boolean,
    setData: (data: IClientInitialValues) => void
    fileList: UploadFile<any>[],
    isEditing?: boolean,
    setImageUrl: (src: string) => void
}

const ClientForm = (props: IClientFormProps) => {

    const { onSubmit, beforeUpload, handleUploadChange, imageUrl, uploadButton, data, isSubmitting, fileList, setImageUrl } = props
    const [form] = Form.useForm()
    const [isCameraEnabled, setIsCameraEnabled] = useState<boolean>(false);

    const { Option } = Select;

    useEffect(() => {
        if (form && data) form.setFieldsValue({ ...data })
    }, [data])

    const prefixCountryCodeSelector = (
        <Form.Item name="phonePrefix" noStyle>
            <Select style={{ width: 75 }}>
                <Option value="506" >+506</Option>
                <Option value="1">+1</Option>
            </Select>
        </Form.Item>
    );

    const toggleModal = () => setIsCameraEnabled(!isCameraEnabled)


    return (
        <>
            <CapturePhotoModal toggleModal={toggleModal} isModalOpen={isCameraEnabled} setImageUrl={setImageUrl} />
            <Form
                name="basic"
                layout='vertical'
                form={form}
                style={{ width: '100%' }}
                initialValues={{ ...data }}
                onFinish={onSubmit}
                autoComplete="off"
            >
                <Form.Item>
                    <div style={{ display: 'flex', columnGap: '5px' }}>
                        {imageUrl && !fileList.length ? <Avatar size={100} src={imageUrl} /> : null}
                        <Upload
                            name="avatar"
                            listType="picture-circle"
                            className="avatar-uploader"
                            beforeUpload={beforeUpload}
                            onChange={handleUploadChange}
                            multiple={false}
                            showUploadList={{ showPreviewIcon: false }}
                            fileList={fileList}
                            maxCount={1}
                            accept='image/png, image/jpeg'
                        >
                            {uploadButton}
                        </Upload>
                        <Button onClick={toggleModal}>Capturar Foto</Button>
                    </div>
                    <Form.Item
                        label="Nombre"
                        name="name"
                        rules={[
                            { required: true, message: 'Este campo es requerido' },
                        ]}
                        style={{ display: 'inline-block', width: '50%' }}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Apellido"
                        name="lastName"
                        rules={[{ required: true, message: 'Este campo es requerido' }]}
                        style={{ display: 'inline-block', width: '50%' }}
                    >
                        <Input style={{ marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item
                        label="Cédula"
                        name="userId"
                        rules={[{ required: true, message: 'Este campo es requerido' }]}
                        style={{ display: 'inline-block', width: '50%' }}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Teléfono"
                        name="phoneNumber"
                        rules={[{ required: true, message: 'Este campo es requerido' }]}
                        style={{ display: 'inline-block', width: '50%' }}

                    >
                        <Input
                            addonBefore={prefixCountryCodeSelector}
                            style={{ marginLeft: 10, width: '100%' }}
                            value={data.phoneNumber} />
                    </Form.Item>

                    <Form.Item
                        label="Correo Electrónico"
                        name="email"
                        rules={[
                            { required: true, message: 'Este campo es requerido' },
                            { pattern: /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm, message: "Formato de correo no válido" }]}
                        style={{ display: 'inline-block', width: '50%' }}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Fecha de Ingreso"
                        name="enterDate"
                        rules={[{ required: true, message: 'Este campo es requerido' }]}
                        style={{ display: 'inline-block', width: '50%' }}
                    >
                        <DatePicker style={{ width: '100%', marginLeft: 10 }} placeholder='' />
                    </Form.Item>

                    <Form.Item
                        label="Fecha de Pago"
                        name="paymentDate"
                        rules={[{ required: true, message: 'Este campo es requerido' }]}
                        style={{ display: 'inline-block', width: '50%' }}
                    >
                        <DatePicker style={{ width: '100%' }} placeholder='' />
                    </Form.Item>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={isSubmitting}>
                        Guardar
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default ClientForm